/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultCounterCard from "components/Cards/CounterCards/DefaultCounterCard";

function Counters() {
  return (
    <MKBox component="section" pt={3} pb={8}>
      <Container>
        <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={103}
              separator=","
              title="Community service activities"
              description="Addressing 14 of the greatest social needs"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={142069}
              separator=","
              title="People in need served in the past year"
              description="Equivalent to 55% of the population
              of Penrith &amp; Mt Druitt combined"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              prefix="$"
              count={8447317}
              separator=","
              title="Social impact value"
              description="To the City of Penrith &amp; Mt Druitt
              2770 each year"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
