// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
// import DefaultInfoCard from "components/Cards/InfoCards/DefaultInfoCard";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import YouTubeIcon from "@mui/icons-material/YouTube";
// import EmailIcon from "@mui/icons-material/Email";

function Information() {
  return (
    <MKBox component="section" py="3rem" mt="4rem">
      <Container>
        <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
          <MKTypography variant="h3">
            Put the event dates in your calendar NOW & join us in praying for this incredible move
            of God!
          </MKTypography>
        </Grid>
        <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
          <MKTypography variant="h5" my="2rem">
            For updates on the event like or follow One Church Penrith on Facebook & Twitter and
            share with your friends.
          </MKTypography>
        </Grid>

        {/* <Grid container sx={12} spacing={3} justifyContent="center" flexDirection="row"> */}
        <Grid container xs={12} /* spacing={3} */ justifyContent="center" flexDirection="row">
          <MKTypography
            item
            mx="3rem"
            my="1.5rem"
            fontSize="7rem"
            component="a"
            href="https://www.facebook.com/onechurchpenrith"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </MKTypography>
          <MKTypography
            item
            mx="3rem"
            my="1.5rem"
            fontSize="7rem"
            component="a"
            href="https://twitter.com/1churchpenrith"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon />
          </MKTypography>
          {/* <MKTypography item mx="3rem" my="1.5rem" fontSize="7rem">
            <EmailIcon />
          </MKTypography> */}
        </Grid>
        <Grid container item xs={12} justifyContent="center" md={8} mx="auto" textAlign="center">
          <MKTypography variant="h3">We look forward to seeing you at the tent!</MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
