// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// @mui material icons
import SvgIcon from "@mui/material/SvgIcon";
import MaterialAssistanceIcon from "@mui/icons-material/ShoppingCart";
import PastoralCareIcon from "@mui/icons-material/EmojiFoodBeverage";
import CommunityConnectionIcon from "@mui/icons-material/Hub";
import FamilyIcon from "@mui/icons-material/FamilyRestroom";
// import CottageIcon from '@mui/icons-material/Cottage';
// import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';

// React Download Link
// import DownloadLink from "react-download-link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React layouts
import DefaultInfoCard from "components/Cards/InfoCards/DefaultInfoCard";
// import CenteredBlogCard from "components/Cards/BlogCards/CenteredBlogCard";
import RotatingDownloadCard from "components/Cards/RotatingDownloadCard";
import RotatingDownloadCardFront from "components/Cards/RotatingDownloadCard/RotatingDownloadCardFront";
import RotatingDownloadCardBack from "components/Cards/RotatingDownloadCard/RotatingDownloadCardBack";
import naybaCardFront from "assets/img/pages/public-site/nayba-card-front.jpg";
import naybaCardBack from "assets/img/pages/public-site/nayba-card-back.jpg";

// NAYBA promo image
// import NaybaReportFile from "assets/docs/NAYBA-Impact-Audit_Penrith-MtDruitt_Nov21.pdf";

function Information() {
  // !HACK - replace this with a proper Axios/whatever call later
  const DownloadLinkPDF = `${process.env.PUBLIC_URL}/assets/docs/NAYBA-Impact-Audit_Penrith-MtDruitt_Nov21.pdf`;

  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon={<SvgIcon component={MaterialAssistanceIcon} alt="icon" color="inherit" />}
                    color="info"
                    iconSize="3rem"
                    title="Material assistance"
                    description="Food and clothing for those experiencing homelessness or financial insecurity."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon={<SvgIcon component={PastoralCareIcon} alt="icon" color="inherit" />}
                    color="info"
                    iconSize="3rem"
                    title="Pastoral care"
                    description="Chaplaincy service in schools, hospitals, etc."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon={
                      <SvgIcon component={CommunityConnectionIcon} alt="icon" color="inherit" />
                    }
                    color="info"
                    iconSize="3rem"
                    title="Community connection"
                    description="Efforts to foster social cohesion, reduce isolation, and build community."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon={<SvgIcon component={FamilyIcon} alt="icon" color="inherit" />}
                    color="info"
                    iconSize="3rem"
                    title="Counselling &amp; Family services"
                    description="Relationships, mental health, mentoring, behaviour change, parenting
                      classes, parent &amp; child support groups."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            lg={4}
            sx={{ ml: "auto", mt: { xs: 3, lg: 0 }, width: 612, height: 527 }}
          >
            <RotatingDownloadCard>
              <RotatingDownloadCardFront
                bgColor="dark"
                opacity={0.5}
                icon="touch_app"
                iconSize="4rem"
                image={naybaCardFront} // 612 x 792 px
                textColor="light"
                fontWeight="bold"
                // title="NAYBA Impact Audit"
                description="NAYBA Australia recently conducted an audit in Western Sydney for 
                the City of Penrith &amp; Mt Druitt (2770) and the results show that the work 
                of churches in the region really is 'good news', especially for those in need."
              />
              <RotatingDownloadCardBack
                bgColor="dark"
                opacity={0.75}
                image={naybaCardBack}
                textColor="light"
                fontWeight="bold"
                title="Find out more..."
                description="As this report reveals, local churches and Christian organisations 
                play a critical role in the broader community - not just meeting needs, but 
                building relationships and transforming lives."
                action={{
                  // type: "internal",
                  type: "external",
                  // filename: "NAYBA-Impact-Audit_Penrith-MtDruitt_Nov21.pdf", // ############# this wasn't commented out
                  // route: "assets/docs/NAYBA-Impact-Audit_Penrith-MtDruitt_Nov21.pdf",
                  route: DownloadLinkPDF,
                  // THIS WORKS ==> "https://assets.website-files.com/605a24976279286069786c34/622e47eed1256c3e36bc47ad_NAYBA%20Impact%20Audit_Penrith%20%26%20Mt%20Druitt_Final%20Report_Digital.pdf",
                  // route="assets/docs/NAYBA-Impact-Audit_Penrith-MtDruitt_Nov21.pdf"
                  // route={NaybaReportFile}
                  // route="static/media/NAYBA-Impact-Audit_Penrith-MtDruitt_Nov21.pdf"
                  // color: "info", // ############# this wasn't commented out
                  label: "download the report",
                }}
              />
            </RotatingDownloadCard>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
