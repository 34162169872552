/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react components
// import React, { useEffect, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Images
import organisations from "services/organisations.service";
// const imageUrl = "https://i.imgur.com/fHyEMsl.jpg";

function ChurchIcons() {
  /* const [img, setImg] = useState();

  // const fetchImage = async () => {
  const fetchImage = async (imageUrl) => {
    const res = await fetch(imageUrl);
    const imageBlob = await res.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);
    setImg(imageObjectURL);
  };

  useEffect(() => {
    fetchImage();
  }, []); */

  // <Grid item xs={6} md={4} lg={2} sx={{ justifyContent: "center", alignItems: "center" }}></Grid>

  const renderOrganisationIcons = organisations.map(({ name, logoImage, url }) => (
    <Link
      key={name}
      href={url}
      target="_blank"
      rel="noopener"
      sx={{ justifyContent: "center", alignItems: "center" }}
    >
      <MKBox
        key={url}
        component="img"
        src={logoImage}
        alt={name}
        maxHeight="5rem"
        maxWidth="100%"
        opacity={0.7}
        sx={{ justifyContent: "center", alignItems: "center", mx: "2rem", my: "1.5rem" }}
      />
      {/* <img src={img} alt="icons" /> */}
    </Link>
  ));

  return (
    <MKBox
      component="section"
      pt={3}
      pb={8}
      sx={{ justifyContent: "center", alignItems: "center" }}
    >
      <Container>
        <Grid
          container
          spacing={3}
          sx={{ mb: 12, mt: 6, justifyContent: "center", alignItems: "center" }}
        >
          {renderOrganisationIcons}
        </Grid>
      </Container>
    </MKBox>
  );
}
// alignItems="center" justifyContent="center"
export default ChurchIcons;
