// react-router-dom components
import { Link as RouterLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function DetailedFooter({ content }) {
  const { brand, socials, menus, copyright, createdBy } = content; // inclusion

  // Copyright date and text
  const year = new Date().getFullYear();
  const { preText, linkText, route: copyRoute, postText } = copyright;

  return (
    <MKBox component="footer">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} sx={{ ml: "auto", mb: 3, alignSelf: "flex-end" }}>
            <MKBox
              display="flex"
              direction="row"
              justifyContent="right"
              alignItems="center"
              mr="3rem"
            >
              <MKBox component={RouterLink} to="/" mt={1} mb={0}>
                <MKBox component="img" src={brand.image} alt={brand.name} maxHeight="2rem" mb={0} />
              </MKBox>
            </MKBox>
            <MKBox display="flex" justifyContent="right" alignItems="center" mt={3} mr="3rem">
              {socials.map(({ icon, link }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="h5"
                  color="dark"
                  opacity={0.8}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
          </Grid>
          {menus.map(({ name: title, route: titleRoute, items }, key) => (
            <Grid key={title} item xs={6} md={2} ml={key === 0 ? { xs: 0, lg: "auro" } : 0}>
              {titleRoute ? (
                <MKTypography
                  component={RouterLink}
                  to={titleRoute}
                  display="block"
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                  mb={1}
                >
                  {title}
                </MKTypography>
              ) : (
                <MKTypography
                  display="block"
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                  mb={1}
                >
                  {title}
                </MKTypography>
              )}
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {items.map(({ name, route, href }) => (
                  <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                    {href ? (
                      <MKTypography
                        component="a"
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {name}
                      </MKTypography>
                    ) : (
                      <MKTypography
                        component={RouterLink}
                        to={route}
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {name}
                      </MKTypography>
                    )}
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          ))}
          {/* <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
            <MKTypography variant="button" fontWeight="regular">
              {inclusion.acknowledgeTraditionalOwners}
            </MKTypography>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center", mt: 0 }}>
            <MKBox
              component="img"
              src={inclusion.icon}
              alt="inclusion icon"
              maxHeight="2rem"
              mb={0}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center", mt: 0 }}>
            <MKTypography variant="button" fontWeight="regular">
              {inclusion.diversity}
            </MKTypography>
          </Grid> */}
          <Grid item xs={12} sx={{ textAlign: "center", mb: 1 }}>
            {copyRoute ? (
              <MKTypography variant="button" fontWeight="regular">
                {preText} Copyright &copy;&nbsp;{year}&nbsp;
                <MKTypography
                  component={RouterLink}
                  to={copyRoute}
                  variant="button"
                  fontWeight="regular"
                >
                  {linkText}
                </MKTypography>
                &nbsp;&nbsp;{postText}
              </MKTypography>
            ) : (
              <MKTypography variant="button" fontWeight="regular">
                {preText} Copyright &copy;&nbsp;{year}&nbsp;{linkText}&nbsp;&nbsp;{postText}
              </MKTypography>
            )}
            <MKTypography variant="button" fontWeight="regular">
              <br />
              {createdBy.text}
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Typechecking props for the DetailedFooter
DetailedFooter.propTypes = {
  content: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.bool])
  ).isRequired,
};

export default DetailedFooter;
