// import { useRef } from "react";

// SwiperJS
// import SwiperCore, { Autoplay, Navigation } from "swiper";

// SwiperJS react components
/* import {
  Swiper,
  SwiperSlide,
} from "swiper/react/swiper-react"; */ /* esl int-dis able imp ort/no-unres olved */ // ERROR

// SwiperJS styles
// https://github.com/import-js/eslint-plugin-import/issues/2266
// import "swiper/swiper.min.css"; /* eslint-disable import/no-unresolved */ // ERROR
// import "swiper/modules/navigation/navigation.min.css"; /* eslint-disable import/no-unresolved */ // ERROR

// React Swipe
// https://github.com/lyfeyaj/swipe/tree/master/react
import Swipe, { SwipeItem } from "swipejs/react";

// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import ComplexReviewCard from "components/Cards/ReviewCards/ComplexReviewCard";

// Images
import KarenMcKeownPhoto from "assets/img/pages/public-site/statements-of-support/karen-mckeown.jpg";
import KayleenMantonPhoto from "assets/img/pages/public-site/statements-of-support/kayleen-manton.jpg";
import DavidSimmonsPhoto from "assets/img/pages/public-site/statements-of-support/david-simmons.jpg";
import TanyaDaviesPhoto from "assets/img/pages/public-site/statements-of-support/tanya-davies.jpg";
import ShaneConlonPhoto from "assets/img/pages/public-site/statements-of-support/shane-conlon.jpg";

import PenrithCouncilLogo from "assets/img/pages/public-site/statements-of-support/penrith-council-logo.png";
import APWMLogo from "assets/img/pages/public-site/statements-of-support/apwm-logo.png";
import PenrithPanthersLogo from "assets/img/pages/public-site/statements-of-support/penrith-panthers-logo.png";
import NSWGovtLogo from "assets/img/pages/public-site/statements-of-support/nsw-govt-logo.png";
import NTSLogo from "assets/img/pages/public-site/statements-of-support/nts-logo.png";

// Swipe style (keeps cards on the same row)
import "swipejs/style.css";

function StatementsOfSupport() {
  // install SwiperJS modules
  // SwiperCore.use([Autoplay, Navigation]);

  // Swiper navigation buttons styles
  const navigationStyles = {
    position: "absolute",
    top: 0,
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "15%",
    height: "100%",
    textAlign: "center",
    opacity: 0.5,
    cursor: "pointer",
    transition: "opacity 0.15s ease",

    "&:hover, &:focus": {
      opacity: 1,
    },
  };

  // this.state = {
  const slides = [
    {
      id: 0,
      jsx: (
        <SwipeItem>
          <Container>
            <ComplexReviewCard
              image={KarenMcKeownPhoto}
              prefix="- Statement of Support"
              title="Penrith City Council commends and is grateful for the practical and
              spiritual support given to our communities"
              review="Penrith City Council commends and is grateful for the practical and
                spiritual support given to our communities by a diverse range of faith-based
                organisations in our City.
                Together, these organisations and their volunteers have made and continue
                to make a positive difference - connecting isolated residents to community,
                providing food and clothing as well as literal and figurative shelter.
                It is our diversity and the willingness of the community to assist others that
                helps make Penrith such a special place."
              author={{
                logo: PenrithCouncilLogo,
                name: "Mayor Karen McKeown OAM",
                role: "Penrith City Council",
              }}
            />
          </Container>
        </SwipeItem>
      ),
    },
    {
      id: 1,
      jsx: (
        <SwipeItem>
          <Container>
            <ComplexReviewCard
              image={KayleenMantonPhoto}
              prefix="- Statement of Support"
              title="The Church should be a reflection of Christ's words and deeds - especially when
            engaging with the Indigenous people of this region"
              review="As Christians, we are called to fulfil the Great Commission (Matthew 28:18). For
            our church, this means making disciples and demonstrating the practical love
            of Jesus to those in need through things like food delivery.
            The Church should be a reflection of Christ's words and deeds - especially when
            engaging with the Indigenous people of this region. We do this best when we all
            work together in a way that is both biblical and cultural.
            My prayer is that this report will lead to more opportunities for us to love others
            as we are loved by God."
              author={{
                logo: APWMLogo,
                name: "Kayleen Manton",
                role: "Mount Druitt Indigenous Church",
              }}
            />
          </Container>
        </SwipeItem>
      ),
    },
    {
      id: 2,
      jsx: (
        <SwipeItem>
          <Container>
            <ComplexReviewCard
              image={DavidSimmonsPhoto}
              prefix="- Statement of Support"
              title="They work hard behind the scenes in our community, doing it out of love for God 
            and a desire for the good of all people"
              review="I've been around footy clubs for almost half my life. I know the success of the
            great clubs we see on TV and in the papers wouldn't be possible without those
            working behind the scenes. They don't do it for fame or glory or the promise of
            big money, but because they love what they do and want to see the club succeed.
            It's the same with churches and faith-based organisations. They work hard
            behind the scenes in our community, doing it out of love for God and a desire for
            the good of all people."
              author={{
                logo: PenrithPanthersLogo,
                name: "David Simmons",
                role: "Penrith Panthers Club Chaplain",
              }}
            />
          </Container>
        </SwipeItem>
      ),
    },
    {
      id: 3,
      jsx: (
        <SwipeItem>
          <Container>
            <ComplexReviewCard
              image={TanyaDaviesPhoto}
              prefix="- Statement of Support"
              title="Their practical, financial and relational support strengthens
          the social, emotional and mental well-being of our communities"
              review="As a representative of the people of Western Sydney for over 13 years I
            have witnessed the service that churches and faith-based organisations
            provide to our community, especially during hardships like bushfires and
            the pandemic. Their practical, financial and relational support strengthens
            the social, emotional and mental well-being of our communities.
            I hope this report highlights the significance of these organisations and
            encourages government to strengthen their partnerships to ensure an even
            greater support network can be built to serve our communities."
              author={{
                logo: NSWGovtLogo,
                name: "Tanya Davies MP",
                role: "State Member for Mulgoa",
              }}
            />
          </Container>
        </SwipeItem>
      ),
    },
    {
      id: 4,
      jsx: (
        <SwipeItem>
          <Container>
            <ComplexReviewCard
              image={ShaneConlonPhoto}
              prefix="- Statement of Support"
              title="Together we can build a stronger and better City"
              review="Our region is richer thanks to the hours of dedicated service invested every day
            by staff and volunteers of Christian churches and organisations.
            From the provision of formal education to the provision of food and shelter;
            from the deployment of volunteers for natural disasters to the deployment of
            chaplains for local schools and hospitals.
            This Audit is a significant initiative that will not only highlight the work being
            done, but also identify opportunities for greater investment from business, so
            that together we can build a stronger and better City."
              author={{
                logo: NTSLogo,
                name: "Shane Conlon",
                role: "Managing Director, National Technical Services Pty Ltd",
              }}
            />
          </Container>
        </SwipeItem>
      ),
    },
  ];
  //  };

  // SwiperJS navigation buttons ref
  // const navigationPrevRef = useRef(null);
  // const navigationNextRef = useRef(null);

  let swipeEl = {};

  return (
    <MKBox py={8} position="relative">
      <MKTypography
        variant="h2"
        color="dark"
        sx={{
          ...navigationStyles,
          left: 0,
        }}
        // ref={navigationPrevRef}
        onClick={() => swipeEl.prev()}
      >
        <Icon>chevron_left</Icon>
      </MKTypography>
      <Swipe
        ref={(o) => {
          swipeEl = o;
        }}
        // onInit={({ /* params, */ navigation }) => {
        // const { navigation: nav } = params;

        // nav.prevEl = navigationPrevRef.current;
        // nav.nextEl = navigationNextRef.current;
        // navigation.init();
        // navigation.update();
        // }}
        // autoplay={{ delay: 5000 }}
        speed={2000}
        auto={5000}
        // spaceBetween={0}
        // slidesPerView={1}
        continuous // value must be omitted for boolean attributes (so this is 'true')
        autoRestart // value must be omitted for boolean attributes (so this is 'true')
      >
        {slides.map((slide) => (
          <SwipeItem
            key={slide.id}
            className="custom-swipe-item-class"
            onClick={() => swipeEl.next()}
          >
            {slide.jsx}
          </SwipeItem>
        ))}
      </Swipe>
      <MKTypography
        variant="h2"
        color="dark"
        sx={{
          ...navigationStyles,
          right: 0,
        }}
        // ref={navigationNextRef}
        onClick={() => swipeEl.next()}
      >
        <Icon>chevron_right</Icon>
      </MKTypography>
    </MKBox>
  );
}

export default StatementsOfSupport;
