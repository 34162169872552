/** 
  All of the routes for the One Church Penrith website are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
// import Icon from "@mui/material/Icon";
/* import FindInPageIcon from "@mui/icons-material/FindInPage";
import ChurchIcon from "@mui/icons-material/Church";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"; */
import CoPresentIcon from "@mui/icons-material/CoPresent";
import FestivalIcon from "@mui/icons-material/Festival";
// import SupportIcon from "@mui/icons-material/Support";
// import LinkIcon from "@mui/icons-material/Link";
// import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";

// ! `path` used to be called `route`, so this is a breaking change for all components that use this array.
const routes = [
  /* {
    key: ,
    route: "/services",
    name: "services",
    icon: <FindInPageIcon />, // <LinkIcon />,
    description: "Answers to common questions, legal documents, and contact details",
  },
  {
    key: ,
    route: "/churches",
    name: "churches",
    icon: <ChurchIcon />,
    description: "Over 100 churches around the Penrith and Mt Druitt are here for you",
  },
  {
    key: ,
    route: "/map",
    name: "map",
    icon: <LocationOnIcon />,
    description: "Find a church or service close to you",
  },
  {
    key: ,
    route: "/events",
    name: "what's on",
    icon: <CalendarMonthIcon />,
    description: "Upcoming events and activities",
  }, */
  {
    name: "The Main Event",
    key: 1.1,
    icon: <FestivalIcon />,
    description: "Tim Hall 7pm each night 5-10 May at Penrith Paceway",
    // route: "/events/tent", // internal page
    href: "https://themaineventpenrith.com.au/", // external site
  },
  {
    name: "Business Network",
    key: 2.1,
    icon: <CoPresentIcon />,
    description: "OCP Business Network",
    route: "/kbn",
  },
  /* {
    name: "The Great Wait",
    key: 1.1,
    icon: <CalendarMonthIcon />,
    description: "A time of focused prayer",
    route: "/events/the-great-wait",
  },
  {
    key: ,
    name: "about us",
    icon: <CoPresentIcon />,
    description: "Answers to common questions, legal documents, and contact details",
    // columns: 3,
    // rowsPerColumn: 2,
    // dropdown: true,
    collapse: [
      {
        key: ,
        route: "/about-us/contact",
        name: "contact us",
        // icon: <Icon>Forum</Icon>,
      },
      {
        key: ,
        route: "/about-us/team",
        name: "team",
        // icon: <Icon>AccountTree</Icon>,
      },
      {
        key: ,
        route: "/about-us/blog",
        name: "blog",
        // icon: <Icon>AccountTree</Icon>,
      },
      {
        key: ,
        route: "/about-us/history",
        name: "history",
        // icon: <Icon>AccountTree</Icon>, // ViewTimelineIcon
      },
    ],
  }, */
  /* {
    key: 2,
    name: "legal", // name: "support",
    icon: <SupportIcon />,
    description: "Answers to common questions, legal documents, and contact details",
    // columns: 3,
    // rowsPerColumn: 2,
    // dropdown: true,
    collapse: [
      // {
        // key: ,
        // route: "/faq",
        // name: "FAQ",
        // icon: <Icon>Help</Icon>,
      // },
      {
        key: 2.1,
        route: "/privacy",
        name: "privacy",
        // icon: <Icon>PrivacyTip</Icon>,
      },
      {
        key: 2.2,
        route: "/terms",
        name: "terms & conditions",
        // icon: <Icon>Gavel</Icon>,
      },
      {
        key: 2.3,
        route: "/social-media-terms",
        name: "social media terms of use",
        // icon: <Icon>Gavel</Icon>,
      },
    ],
  }, */
];

export default routes;
