/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
 * The base colors for the Material Kit 2 PRO React.
 * You can add new color using this file.
 * You can customized the colors for the entire Material Kit 2 PRO React using thie file.
 */

export default {
  background: {
    default: "#e0e0de",
  },

  text: {
    // main: "#3c517d",
    main: "#565656",
    // focus: "#3c517d",
    focus: "#565656",
  },

  transparent: {
    main: "transparent",
  },

  white: {
    main: "#ffffff",
    focus: "#ffffff",
  },

  black: {
    light: "#000000",
    main: "#000000",
    focus: "#000000",
  },

  primary: {
    // main: "#9679fc",
    main: "#ffd800",
    focus: "#9679fc",
  },

  secondary: {
    main: "#54595f", // #8f7fc9
    focus: "#5f48ac",
  },

  info: {
    main: "#ebb73a",
    focus: "#4265ac",
  },

  success: {
    main: "#3ea841",
    focus: "#6bc96e",
  },

  warning: {
    main: "#fc9c4c",
    focus: "#fcb579",
  },

  error: {
    main: "#ad5077",
    focus: "#c989a5",
  },

  light: {
    main: "#f0f2f5",
    focus: "#f0f2f5",
  },

  dark: {
    main: "#2d3d5e",
    focus: "#1e283e",
  },

  grey: {
    100: "#f8f9fa",
    200: "#f0f2f5",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#6c757d",
    700: "#495057",
    800: "#343a40",
    900: "#212529",
  },

  gradients: {
    primary: {
      main: "#ab93fd",
      state: "#7f5bfb",
    },

    secondary: {
      main: "#887ab9",
      state: "#5d5290",
    },

    info: {
      main: "#71b6ef",
      state: "#2d69c1",
    },

    success: {
      main: "#6bc96e",
      state: "#3ea841",
    },

    warning: {
      main: "#fcb579",
      state: "#fc9c4c",
    },

    error: {
      main: "#c989a5",
      state: "#ad5077",
    },

    light: {
      main: "#EBEFF4",
      state: "#CED4DA",
    },

    dark: {
      main: "#3b4b74",
      state: "#122243",
    },
  },

  badgeColors: {
    primary: {
      background: "#cabafe",
      text: "#7963e4",
    },

    secondary: {
      background: "#c1aaea",
      text: "#4a3785",
    },

    info: {
      background: "#a7b8fd",
      text: "#314b7f",
    },

    success: {
      background: "#aedbaf",
      text: "#258e28",
    },

    warning: {
      background: "#ffd297",
      text: "#ce4f1c",
    },

    error: {
      background: "#e3c3d1",
      text: "#af1f42",
    },

    light: {
      background: "#ffffff",
      text: "#c7d3de",
    },

    dark: {
      background: "#79a0e9",
      text: "#173774",
    },
  },

  coloredShadows: {
    primary: "#9679fb",
    secondary: "#250d3d",
    info: "#2cb7dc",
    success: "#3ea842",
    warning: "#ffa94c", // old custom colour was #fcb579
    error: "#ad5078",
    light: "#ADB5BD",
    dark: "#404040",
  },

  inputBorderColor: "#D2D6DA",

  tabs: {
    indicator: { boxShadow: "#DDD" },
  },

  socialMediaColors: {
    facebook: {
      main: "#3b5998",
      dark: "#344e86",
    },

    twitter: {
      main: "#55acee",
      dark: "#3ea1ec",
    },

    instagram: {
      main: "#125688",
      dark: "#0e456d",
    },

    linkedin: {
      main: "#0077b5",
      dark: "#00669c",
    },

    pinterest: {
      main: "#cc2127",
      dark: "#b21d22",
    },

    youtube: {
      main: "#e52d27",
      dark: "#d41f1a",
    },

    vimeo: {
      main: "#1ab7ea",
      dark: "#13a3d2",
    },

    slack: {
      main: "#3aaf85",
      dark: "#329874",
    },

    dribbble: {
      main: "#ea4c89",
      dark: "#e73177",
    },

    github: {
      main: "#24292e",
      dark: "#171a1d",
    },

    reddit: {
      main: "#ff4500",
      dark: "#e03d00",
    },

    tumblr: {
      main: "#35465c",
      dark: "#2a3749",
    },
  },
};
