/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "layouts/Navbars/DefaultNavbar";
import DefaultFooter from "layouts/Footers/DefaultFooter";

// Routes
// import { routes, footerRoutes, DefaultNavbar, DefaultFooter } from "internal.js";
import navbarRoutes from "routes/navbar.routes";
import footerRoutes from "routes/footer.routes";

function Terms() {
  return (
    <>
      <DefaultNavbar
        routes={navbarRoutes}
        /* action={{
          type: "internal",
          route: "/login",
          label: "login",
          color: "dark",
        }} */
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Terms &amp; Conditions
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Last Updated: 21 April 2022. The electronic version of this policy is the
                    approved and current version. Any printed version is uncontrolled and may not be
                    current.
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Introduction
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Thank you for visiting the One Church Penrith website. This website may be
                    available through other addresses or channels.
                    <br />
                    For the purpose of these Terms and Conditions, &apos;One Church Penrith&apos;
                    (and &apos;we&apos;, &apos;our&apos;, &apos;us&apos;) means:
                    <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Penrith Christian Ministers Association Inc. (INC3476084, ABN: 57 642 642
                          039)
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Any organisations or bodies corporate owned or operated by any of the
                          bodies above.
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                    By accessing this site, you are deemed to have read and are bound to the
                    unconditional acceptance and compliance with the Terms and Conditions set out
                    below. [You can also access and read our Privacy Policy which is available on
                    our website.]
                    <br />
                    <br />
                    Information has been provided as complete and in good faith without any express
                    or implied warranty and is subject to change and is as up-to-date as our
                    resources permit.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Terms &amp; Conditions
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    <MKBox component="ol" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          One Church Penrith reserves the right to change the Terms &amp; Conditions
                          without notification of change.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          All copyright, trademarks, design rights, patents and other intellectual
                          property rights (both registered and unregistered) appearing on this
                          website (&quot;OCP IP&quot;) belong to One Church Penrith exclusively, or
                          is licensed to One Church Penrith, unless otherwise indicated. Nothing in
                          these Terms and Conditions grants any right and/or licence for you to use
                          or otherwise deal with any one or more elements of OCP IP except as
                          expressly authorised in writing by One Church Penrith.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          All intellectual property rights in and to all logos and slogans are
                          subject to copyright obligations to owned by One Church Penrith and, along
                          with all information, text, graphics, advertisements, audio, and
                          multimedia found on this site are not to be copied, reproduced, reused,
                          reposted, repurposed, transmitted, adapted, republished, broadcast,
                          distributed or uploaded without written authorised permission from One
                          Church Penrith.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Information contained in Media and Appeal resources are provided for use
                          within the general intent of informing the public. Use is as identified on
                          the individual document. Restrictions apply as above to the use of logos.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          As a condition of use of the One Church Penrith website, you undertake not
                          to use any of the material on this website in a way that:
                          <MKBox component="ol" my={3} ml={6}>
                            <MKBox component="li">
                              <MKTypography variant="body2" color="text" fontWeight="regular">
                                is unlawful or prohibited by these Terms and Conditions;
                              </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                              <MKTypography variant="body2" color="text" fontWeight="regular">
                                is used to impersonate any person or One Church Penrith;
                              </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                              <MKTypography variant="body2" color="text" fontWeight="regular">
                                is used to create content that is defamatory, obscene, abusive or
                                otherwise infringes the copyrights of One Church Penrith; or
                              </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                              <MKTypography variant="body2" color="text" fontWeight="regular">
                                breaches the copyright or intellectual property of a third party.
                              </MKTypography>
                            </MKBox>
                          </MKBox>
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Transmission of any virus, spyware or other harmful malware or component
                          is prohibited. One Church Penrith makes no warranties or representations
                          that access to the One Church Penrith website will be secure and free from
                          viruses.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          The information on this site has been provided for personal,
                          non-commercial use only, to provide information on the mission and
                          ministry of One Church Penrith.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Information on services of One Church Penrith provided on this website is
                          provided for the generic assistance of the public. The information on this
                          website should in no way be interpreted as professional advice,
                          instruction or opinion on your personal circumstances. Please contact an
                          appropriate health or social services professional for advice specific to
                          your situation. We make no representations or warranties that the
                          information provided on the One Church Penrith website is complete,
                          accurate, reliable, up-to-date or suitable for any particular purpose.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          The One Church Penrith website may contain links to websites operated by
                          third parties. Unless expressly stated otherwise, we do not control,
                          endorse or approve, and are not responsible for, the content on those
                          websites and/or the actions or inactions of the owners/operators of such
                          websites. The inclusion of any links does not imply a recommendation by
                          One Church Penrith or an endorsement of the views expressed within them,
                          unless expressly stated so.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          In no event will One Church Penrith be liable for any loss or damage
                          including without limitation, indirect or consequential loss or damage
                          (including loss of revenue, opportunity or reputation), or any loss or
                          damage of any kind arising from loss of data, information or funds arising
                          out of or in connection with the use of this website. We are not liable
                          for any errors, omissions or defects in the material on the One Church
                          Penrith website.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          You will indemnify One Church Penrith if it suffers any loss or damage or
                          incurs any costs in connection with any breach of these Terms and
                          Conditions or any other legal obligation by you or your use of or conduct
                          on the One Church Penrith website.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          The One Church Penrith website may be accessed throughout Australia and
                          overseas. We make no representation that the One Church Penrith website
                          complies with the laws (including intellectual property laws) of any
                          country outside Australia. If you access the One Church Penrith website
                          from outside Australia, you do so at your own risk and are responsible for
                          complying with the laws of the jurisdiction where you access the One
                          Church Penrith website.
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Use of logo and trademarks
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    The One Church Penrith logo is a registered trademark of the Penrith Christian
                    Ministers Association. All uses of the logo by entities outside the Penrith
                    Christian Ministers Association must receive approval in writing prior to use.
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Terms;
