// @mui material components
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// KBN branding
import bgImageYellow from "assets/img/kbn/background-panel.jpg";

// @mui material icons
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import VideoChatIcon from "@mui/icons-material/VideoChat";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function ConnectWithUs() {
  return (
    <Grid
      container
      // columns={1}
      // item
      xs={12}
      sx={{
        backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0),
            rgba(gradients.dark.state, 0)
          )}, url(${bgImageYellow})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      // justifyContent="center"
    >
      <MKBox
        width="100%"
        sx={{
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          placeItems: "center",
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          xs={12}
          py={3}
          px={6}
        >
          <MKTypography variant="h4" /* color="text" */ alignText="center">
            Connect with us...
          </MKTypography>

          <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing="4rem">
            <MKTypography
              key="https://www.linkedin.com/company/one-church-penrith-kingdom-business-network/"
              component="a"
              href="https://www.linkedin.com/company/one-church-penrith-kingdom-business-network/"
              target="_blank"
              rel="noopener noreferrer"
              // variant="body1"
              // color="socialMediaColors.linkedin"
              opacity={0.9}
              mx="1rem"
              fontSize="7rem"
            >
              <LinkedInIcon />
            </MKTypography>
            <MKTypography
              key="https://www.linkedin.com/company/one-church-penrith-kingdom-business-network/events/"
              component="a"
              href="https://www.linkedin.com/company/one-church-penrith-kingdom-business-network/events/"
              target="_blank"
              rel="noopener noreferrer"
              variant="body1"
              opacity={0.9}
              mx="1rem"
              fontSize="7rem"
            >
              <VideoChatIcon />
            </MKTypography>
            <MKTypography
              key="https://www.facebook.com/OCP.KBN"
              component="a"
              href="https://www.facebook.com/OCP.KBN"
              target="_blank"
              rel="noopener noreferrer"
              variant="body1"
              // color="socialMediaColors.facebook"
              opacity={0.9}
              mx="1rem"
              fontSize="7rem"
            >
              <FacebookIcon />
            </MKTypography>
          </Stack>
          <MKTypography variant="h5">
            Join us on the first Thursday of every month at 7am from the comfort of your own home or
            office.
          </MKTypography>
        </Stack>
      </MKBox>
    </Grid>
  );
}

export default ConnectWithUs;
