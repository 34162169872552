import { useEffect, useRef } from "react";
// import { useEffect } from "react";

// rellax
import Rellax from "rellax";

// typed-js
import * as Typed from "typed.js";

// @mui material components
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React theme
// import colors from "assets/theme/base/colors";
// import rgba from "assets/theme/functions/rgba";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKButton from "components/MKButton";

// Material Kit 2 PRO React layouts
import DetailedFooter from "layouts/Footers/DetailedFooter";
import DefaultNavbar from "layouts/Navbars/DefaultNavbar";

// Routes
// import { routes, footerRoutes } from "internal";
import footerRoutes from "routes/footer.routes";
import navbarRoutes from "routes/navbar.routes";

// Images
// import bgImage from "assets/img/Penrith-1435x360.jpg"; // 965W x 360H aspect ratio
// import bgImage from "assets/img/pages/public-site/home-bg-top.png"; // 965W x 360H aspect ratio
import bgImage from "assets/img/pages/public-site/home-bg-top_4650x1735.webp"; // 965W x 360H aspect ratio

// Data Services
import suburbs from "services/suburbs.service";
// import { suburbs } from "internal";

// Page sections
import Information from "./sections/Information";
// import Team from "./sections/Team";
import ChurchIcons from "./sections/ChurchIcons";
import Counters from "./sections/Counters";
import SocialMedia from "./sections/SocialMedia";
import StatementsOfSupport from "./sections/StatementsOfSupport";
// import Newsletter from "./sections/Newsletter";
import ContactList from "./sections/ContactList";

function Home() {
  const headerRef = useRef();
  const typedJSRef = useRef();

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);

  // Setting up typedJS
  useEffect(() => {
    const typedJS = new Typed(typedJSRef.current, {
      strings: suburbs,
      typeSpeed: 90,
      backSpeed: 90,
      backDelay: 200,
      startDelay: 500,
      loop: true,
    });

    return () => typedJS.destroy();
  }, []);

  return (
    <>
      <DefaultNavbar
        routes={navbarRoutes}
        /* action={{
          type: "internal",
          route: "/login",
          label: "login",
          color: "info",
        }} */
        sticky
      />
      <MKBox
        ref={headerRef}
        minHeight="75vh"
        width="100%"
        sx={{
          /* backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`, */
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0),
              rgba(gradients.dark.main, 0)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="text"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Churches serving our city together in
            </MKTypography>
            <MKTypography
              variant="h1"
              color="secondary"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              <span ref={typedJSRef} />
            </MKTypography>
            <MKTypography
              variant="subtitle1"
              color="text"
              // fontWeight="bold"
              // opacity={0.7}
              mt={1}
              mb={3}
            >
              “So then, let us aim for harmony in the church and try to build each other up.” -
              Romans 14:19
            </MKTypography>
            <SocialMedia content={footerRoutes} textColor="white" />
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <Information />
        <StatementsOfSupport />
        {/* <Team /> */}
        <ContactList />
        <ChurchIcons />
        {/* <Newsletter /> */}
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DetailedFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
