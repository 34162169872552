// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import MuiLink from "@mui/material/Link";

// React Download Link
// import DownloadLink from "react-download-link";

// File Saver
// https://thewebdev.info/2021/05/24/how-to-download-file-in-react-js/
// import { saveAs } from "file-saver";

// Axios
// import axios from "axios";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

function RotatingDownloadCardBack({
  bgColor,
  image,
  textColor,
  fontWeight,
  title,
  description,
  opacity,
  action,
}) {
  /* const saveFile = () => {
    saveAs(action.route, action.filename);
  }; */

  return (
    <MKBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="lg"
      coloredShadow={bgColor}
      position="absolute"
      width="100%"
      height="100%"
      top={0}
      left={0}
      zIndex={5}
      sx={{
        backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
          `${linearGradient(
            rgba(gradients[bgColor] ? gradients[bgColor].main : gradients.info.main, opacity),
            rgba(gradients[bgColor] ? gradients[bgColor].main : gradients.info.main, opacity)
          )}, url(${image})`,
        backgroundSize: "cover",
        backfaceVisibility: "hidden",
        transform: "rotateY(180deg)",
      }}
    >
      <MKBox pt={12} pb={2} px={2} textAlign="center" lineHeight={1}>
        <MKTypography variant="h3" color={textColor} gutterBottom>
          {title}
        </MKTypography>
        <MKTypography variant="body2" color={textColor} fontWeight={fontWeight} opacity={0.8}>
          {description}
        </MKTypography>
        {action && (
          <MKBox width="50%" mt={4} mb={2} mx="auto">
            {action.type === "external" ? (
              <MKButton
                component={MuiLink}
                href={action.route}
                target="_blank"
                rel="noreferrer"
                color={textColor}
                size="small"
                fullWidth
              >
                {action.label}
                {/* <DownloadLink
                  label={action.label}
                  filename={action.filename}
                  exportFile={() => Promise.resolve(action.route)}
            /> */}
              </MKButton>
            ) : (
              <MKButton
                component={Link}
                to={action.route}
                color={textColor}
                size="small"
                fullWidth
                // onClick={saveFile}
                /* onClick={axios({
                  method: "get",
                  url: action.route,
                  responseType: "arraybuffer",
                })
                  .then((response) => {
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(
                      new Blob([response.data], { type: "application/octet-stream" })
                    );
                    link.download = action.filename;

                    document.body.appendChild(link);

                    link.click();
                    setTimeout(function () {
                      window.URL.revokeObjectURL(link);
                    }, 200);
                  })
                  .catch((error) => {
                    console.log(error);
                  })} */
              >
                {action.label}
                {/* <DownloadLink
                  label={action.label}
                  filename={action.filename}
                  exportFile={() => Promise.resolve(action.route)}
            /> */}
              </MKButton>
            )}
          </MKBox>
        )}
      </MKBox>
    </MKBox>
  );
}

/*
axios({
  method: "get",
  url: "downloadSamplePDF.php",
  responseType: "arraybuffer"
})
  .then((response) => {
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(
      new Blob([response.data], { type: "application/octet-stream" })
    );
    link.download = "name_of_file_with_extension";

    document.body.appendChild(link);

    link.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(link);
    }, 200);
  })
  .catch((error) => {});
*/

// Setting default props for the RotatingDownloadCardBack
RotatingDownloadCardBack.defaultProps = {
  bgColor: "info",
  opacity: 0.85,
  textColor: "white",
  fontWeight: "regular",
};

// Typechecking props for the RotatingDownloadCardBack
RotatingDownloadCardBack.propTypes = {
  bgColor: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string.isRequired,
  textColor: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  fontWeight: PropTypes.oneOf([false, "light", "regular", "medium", "bold"]),
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  opacity: PropTypes.number,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ]).isRequired,
};

export default RotatingDownloadCardBack;
