// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { ThemeProvider } from "@mui/material/styles";

// KBN branding
import headerImage from "assets/img/kbn/kingdom-business-logo-with-OCP.jpg";
import kbnTheme from "assets/theme/kbn-theme";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import SimpleFooter from "layouts/Footers/SimpleFooter";
import DefaultNavbar from "layouts/Navbars/DefaultNavbar";

// Routes
import footerRoutes from "routes/footer.routes";
import navbarRoutes from "routes/navbar.routes";

// Layout
import Flourishing from "./Flourishing";
import ConnectWithUs from "./Connect";
import Info from "./Info";
import BibleVerse from "./BibleVerse";
// import MeetTheTeam from "./MeetTheTeam";
// import ContactForm from "./ContactForm";

function KingdomBusinessNetwork() {
  return (
    <ThemeProvider theme={kbnTheme}>
      <DefaultNavbar
        routes={navbarRoutes}
        /* action={{
          type: "internal",
          route: "/login",
          label: "login",
          color: "dark",
        }} */
        sticky
      />
      <MKBox component="section" pt={20} pb={0} bgColor="#202020;">
        <Container>
          <MKBox
            display="flex"
            alignItems="center"
            minHeight="40vh"
            mt={-9}
            sx={{
              backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
                `${linearGradient(
                  rgba(gradients.dark.main, 0),
                  rgba(gradients.dark.state, 0)
                )}, url(${headerImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid container justifyContent="center">
            <Flourishing />
            <ConnectWithUs />
            <Info />
            {/* <MeetTheTeam /> */}
            <BibleVerse />
            {/* <ContactForm /> */}
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <SimpleFooter content={footerRoutes} />
      </MKBox>
    </ThemeProvider>
  );
}

export default KingdomBusinessNetwork;
