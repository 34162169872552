// @mui material components
import Grid from "@mui/material/Grid";

// @mui material icons
// import GroupsIcon from "@mui/icons-material/Groups";
// import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
// import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
// import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
// import Diversity3Icon from "@mui/icons-material/Diversity3";
import HubIcon from "@mui/icons-material/Hub";
import SvgIcon from "@mui/material/SvgIcon";

// Material Kit 2 PRO React components
import DefaultInfoCard from "components/Cards/InfoCards/DefaultInfoCard";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Flourishing() {
  return (
    <Grid item xs={12}>
      <MKBox pt={3} pb={3} px={6} bgColor="#ffffff;">
        <MKTypography variant="h3" mt={0} mb={0}>
          One Church Penrith Business is a forum for Christian Business people
        </MKTypography>
        <Grid container justifyContent="flex-start">
          <Grid item xs={8} md={4}>
            <MKBox mb={1}>
              <DefaultInfoCard
                // icon={<SvgIcon component={MedicalServicesIcon} alt="icon" color="inherit" />}
                icon={<SvgIcon component={DirectionsRunIcon} alt="icon" color="inherit" />}
                // icon={<SvgIcon component={SelfImprovementIcon} alt="icon" color="inherit" />}
                // icon={<SvgIcon component={WorkspacePremiumIcon} alt="icon" color="inherit" />}
                color="info"
                iconSize="6rem"
                title="Individual flourishing"
                description="At the heart of our Christian business network is a deep commitment to the flourishing of every individual. We believe that every person is made in the image of God and has unique gifts and talents that can be developed and used for the greater good. By providing opportunities for personal growth and development, we aim to empower individuals to reach their full potential and live fulfilling lives."
                direction="center"
              />
            </MKBox>
          </Grid>
          <Grid item xs={8} md={4}>
            <MKBox mb={1}>
              <DefaultInfoCard
                icon={<SvgIcon component={TrendingUpIcon} alt="icon" color="inherit" />}
                color="info"
                iconSize="6rem"
                title="Business prospering"
                description="We believe that businesses can be a force for good in the world, creating value and contributing to the common good. As a Christian business network, we seek to promote ethical and sustainable business practices that benefit both the company and the wider community. By fostering innovation, collaboration, and creativity, we aim to help businesses thrive and make a positive impact in the world."
                direction="center"
              />
            </MKBox>
          </Grid>
          <Grid item xs={8} md={4}>
            <MKBox mb={1}>
              <DefaultInfoCard
                // icon={<SvgIcon component={GroupsIcon} alt="icon" color="inherit" />}
                // icon={<SvgIcon component={Diversity3Icon} alt="icon" color="inherit" />}
                icon={<SvgIcon component={HubIcon} alt="icon" color="inherit" />}
                color="info"
                iconSize="6rem"
                title="City thriving"
                description="We believe that the transformation of our communities begins with the flourishing of our cities. As a Christian business network, we are committed to working alongside other community organizations to promote social justice, economic development, and environmental sustainability. By investing in our local communities, we can help create thriving cities where everyone has the opportunity to live, work, and thrive."
                direction="center"
              />
            </MKBox>
          </Grid>
        </Grid>
      </MKBox>
    </Grid>
  );
}

export default Flourishing;
