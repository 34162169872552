// SOURCE: https://profile.id.com.au/penrith/about

const suburbs = [
  "Agnes Banks",
  "Badgerys Creek",
  "Berkshire Park",
  "Caddens",
  "Cambridge Gardens",
  "Cambridge Park",
  "Castlereagh",
  "Claremont Meadows",
  "Colyton",
  "Cranebrook",
  "Emu Heights",
  "Emu Plains",
  "Erskine Park",
  "Glenmore Park",
  "Jamisontown",
  "Jordan Springs",
  "Kemps Creek",
  "Kingswood",
  "Leonay",
  "Llandilo",
  "Londonderry",
  "Luddenham",
  "Mount Druitt",
  "Mount Vernon",
  "Mulgoa",
  "North St Marys",
  "Orchard Hills",
  "Oxley Park",
  "Penrith",
  "Regentville",
  "South Penrith",
  "St Clair",
  "St Marys",
  "Wallacia",
  "Werrington",
  "Werrington County",
  "Werrington Downs",
];

export default suburbs;
