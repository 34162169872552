// react-router-dom components
import { Link as RouterLink } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function SimpleFooter({ content }) {
  const { brand, links, copyright, createdBy } = content;

  // Copyright date and text
  const year = new Date().getFullYear();
  const { preText, linkText, route: copyRoute, postTextShort } = copyright;

  const renderLinks = () =>
    links.map((link) => (
      <>
        &nbsp;&nbsp;{" | "}&nbsp;&nbsp;
        {link.href ? (
          <Link component="a" href={link.href} target="_blank" rel="noopener noreferrer">
            {link.name}
          </Link>
        ) : (
          <Link component={RouterLink} to={link.route}>
            {link.name}
          </Link>
        )}
      </>
    ));

  return (
    <Container>
      <MKBox
        width="100%"
        display="flex"
        flexDirection={{ xs: "column", lg: "row" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
          {copyRoute ? (
            <MKTypography variant="button" fontWeight="regular">
              {preText} Copyright &copy;&nbsp;{year}&nbsp;
              <MKTypography
                component={RouterLink}
                to={copyRoute}
                variant="button"
                fontWeight="regular"
              >
                {linkText}
              </MKTypography>
              &nbsp;&nbsp;{postTextShort}
              {renderLinks()}
            </MKTypography>
          ) : (
            <MKTypography variant="button" fontWeight="regular">
              {preText} Copyright &copy;&nbsp;{year}&nbsp;{linkText}&nbsp;&nbsp;{postTextShort}
              {renderLinks()}
            </MKTypography>
          )}
        </Grid>
        <Link component={RouterLink} to={brand.route}>
          <MKBox component="img" src={brand.image} alt={brand.name} maxHeight="2rem" mb={0} />
        </Link>
        <MKTypography variant="button" fontWeight="regular">
          <br />
          {createdBy.text}
        </MKTypography>
      </MKBox>
    </Container>
  );
}

// Typechecking props for the SimpleFooter
SimpleFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default SimpleFooter;
