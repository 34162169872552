// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function BibleVerse() {
  return (
    <Grid item xs={12}>
      <MKBox sx={{ textAlign: "center" }}>
        <MKTypography variant="h2" color="primary" my={2}>
          As iron sharpens iron, so a friend sharpens a friend.
        </MKTypography>
        <MKTypography variant="h6" color="primary" my={2}>
          Prov 27:17
        </MKTypography>
      </MKBox>
    </Grid>
  );
}

export default BibleVerse;
