/*
 * N.B. To avoid circular references, don't import routes, pages, etc.
 * This should just contains strings that the other components use
 * to configure their behaviour.
 */

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import YouTubeIcon from "@mui/icons-material/YouTube";
import EmailIcon from "@mui/icons-material/Email";
// import GitHubIcon from "@mui/icons-material/GitHub";

// Images
// import logoOCP_O from "assets/img/logos/OCP-logo-O.svg";
import OneChurchPenrithLogo from "assets/img/logos/24198.OneChurchPenrith-Logo.svg";
// import InclusionIcon from "assets/img/pages/footer-inclusion.png";
// import TorusKleinLogoSmall from "assets/img/logos/TorusKlein-Logo-small.svg";

export default {
  brand: {
    name: "One Church Penrith",
    image: OneChurchPenrithLogo,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon fontSize="small" />,
      link: "https://www.facebook.com/onechurchpenrith",
    },
    {
      icon: <TwitterIcon fontSize="small" />,
      link: "https://twitter.com/1churchpenrith",
    },
    /* {
      icon: <YouTubeIcon fontSize="small" />,
      link: "https://youtube.com/onechurchpenrith",
    },
    {
      icon: <InstagramIcon fontSize="small" />,
      link: "https://www.instagram.com/onechurchpenrith/",
    }, */
    {
      icon: <EmailIcon fontSize="small" />,
      link: "mailto:contact@onechurchpenrith.org.au",
    },
    /* {
      icon: <GitHubIcon fontSize="small" />,
      link: "https://github.com/onechurchpenrith",
    }, */
  ],
  links: [
    // { name: "Home", route: "/" },
    { name: "Privacy Policy", route: "/privacy" },
    { name: "Terms & Conditions", route: "/terms" },
    // { name: "License", route: "/license" },
    // { name: "Site Map", route: "/site-map" },
    // { name: "Search", route: "/search" },
  ],
  menus: [
    {
      name: "One Church Penrith",
      items: [
        /* { name: "services", route: "/services" },
        { name: "churches", route: "/churches" },
        { name: "map", route: "/map" },
        { name: "what's on", route: "/events" }, */
        { name: "Kingdom Business Network", route: "/kbn" },
        // { name: "The Great Wait", route: "/events/the-great-wait" },
        // { name: "Greater West Tent Revival", route: "/events/tent" }, // internal page
        {
          name: "The Main Event",
          href: "https://themaineventpenrith.com.au/",
        }, // external site
      ],
    },
    /* {
      name: "about us",
      route: "/about-us",
      items: [
        { name: "history", route: "/about-us/history" },
        { name: "blog", route: "/about-us/blog" },
        { name: "members' portal", route: "/portal" },
        { name: "contact us", route: "/about-us/contact" },
      ],
    },
    {
      name: "help & support",
      items: [
        { name: "government support", route: "/" },
        { name: "local businesses", route: "/" },
        { name: "FAQ", route: "/faq" },
      ],
    }, */
    {
      name: "legal",
      items: [
        { name: "terms & conditions", route: "/terms" },
        { name: "social media terms of use", route: "/social-media-terms" },
        { name: "privacy policy", route: "/privacy" },
        // { name: "licenses (EULA)", route: "/license" },
      ],
    },
  ],
  /* inclusion: {
    icon: InclusionIcon,
    // SOURCE: NAYBA Impact Audit - Penrith & Mt Druitt, November 2021
    acknowledgeTraditionalOwners:
      "We acknowledge the Traditional Owners of the land, the Darug people, and pay respect to the Elders past and present." +
      "We also recognise the many Aboriginal and Torres Strait Islander people who reside in the areas now known as Penrith " +
      "and Mt Druitt. We appreciate there is still much work to be done by all Australians, and particularly people of faith, " +
      "if we are to heal the sins of the past, address the challenges of the present, and see true reconciliation in our nation.",
    acknowledgeTraditionalOwnersShort:
      "We acknowledge the Traditional Owners of the land, the Darug people, and pay respect to the Elders past and present.",
    diversity:
      "We value and include people of all cultures, languages, abilities, sexual orientations, gender identities, " +
      "gender expressions and intersex status. We are committed to providing programs that are fully inclusive. " +
      "We are committed to the safety and wellbeing of people of all ages, particularly children.",
    diversityShort:
      "We value and include all people, and are committed to providing programs that are fully inclusive.",
  }, */
  copyright: {
    preText: "All rights reserved. ",
    preTextShort: "",
    linkText: "One Church Penrith",
    route: "/",
    postText:
      "(Penrith Christian Ministers Association Inc., INC3476084, ABN: 57 642 642 039).",
    postTextShort: "(INC3476084).",
  },
  createdBy: {
    text: "Website brought to you by Torus Klein Group Pty Ltd.",
    // url: "https://torusklein.group",
    // logo: TorusKleinLogoSmall,
  },
  light: false,
};
