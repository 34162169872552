// @mui material components
import Grid from "@mui/material/Grid";

// KBN branding
import kbnCrown from "assets/img/kbn/Kingdom-Business-logo-1.png";
import yellowLine from "assets/img/kbn/yellow-line.webp";

// @mui material icons
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Info() {
  return (
    <Grid
      item
      xs={12}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      sx={{
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <MKBox
        bgColor="#ffffff;"
        width="100%"
        sx={{
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          placeItems: "center",
        }}
      >
        <MKTypography variant="h1" mt={0} mb={0} pt="3rem">
          Collaboration &nbsp;&nbsp; Unity &nbsp;&nbsp; Serving
        </MKTypography>
        <MKBox
          key="yellowLine"
          component="img"
          src={yellowLine}
          alt="underline"
          maxHeight="5rem"
          maxWidth="100%"
          opacity={0.7}
          sx={{ justifyContent: "center", alignItems: "center", mx: "2rem", my: "1.5rem" }}
        />
        <MKTypography
          variant="body1"
          color="text"
          sx={{ justifyContent: "center", alignItems: "center", mx: "5rem", my: "1.5rem" }}
        >
          One Church Penrith Business is a network of Christian Business people with a heart to
          transform the city of Penrith through our businesses in unity and collaboration serving
          our local churches of all denominations and all sizes.
        </MKTypography>
        <MKTypography variant="h4" color="text">
          Connect with us…
          <br />
        </MKTypography>
        <MKTypography
          key="https://www.facebook.com/OCP.KBN"
          component="a"
          href="https://www.facebook.com/OCP.KBN"
          target="_blank"
          rel="noreferrer"
          variant="body1"
          color="socialMediaColors.facebook"
          opacity={0.8}
          mx="1rem"
        >
          <FacebookIcon fontSize="large" />
        </MKTypography>
        <MKBox
          component="img"
          src={kbnCrown}
          alt="KBN crown logo"
          width="5%"
          borderRadius="md"
          shadow="md"
          mx="1rem"
        />
        <MKTypography
          key="https://www.linkedin.com/company/one-church-penrith-kingdom-business-network/"
          component="a"
          href="https://www.linkedin.com/company/one-church-penrith-kingdom-business-network/"
          target="_blank"
          rel="noreferrer"
          variant="body1"
          color="socialMediaColors.linkedin"
          opacity={0.8}
          mx="1rem"
        >
          <LinkedInIcon fontSize="large" />
        </MKTypography>
      </MKBox>
    </Grid>
  );
}

export default Info;
