// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// @mui material components
import MuiLink from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import tentInside1 from "assets/img/pages/public-site/tent/tent-inside-1.jpg";

function Banner() {
  // !HACK - replace this with a proper Axios/whatever call later
  const DownloadLinkPDF = `${process.env.PUBLIC_URL}/assets/docs/Greater-West-Tent-Revival.pdf`;

  return (
    <MKBox component="section" py={6} mt={6}>
      <Container sx={{ position: "relative" }}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            item
            xs={12}
            md={7}
            lg={5}
            py={{ xs: 0, sm: 6 }}
            mr="auto"
            position="relative"
          >
            <MKTypography variant="h2" color="main" mb={1}>
              Friday 5th - Wednesday 10th May 2023 at Penrith Paceway
            </MKTypography>
            <MKTypography variant="h4" color="main" mb={1}>
              147 Station Street, Penrith
            </MKTypography>
            <MKTypography variant="body1" color="text1" mt={3}>
              Tim Hall is one of Australia&apos;s leading evangelists. Together
              with his wife Jacque, they have ministered extensively through the
              world. Their forthright and anointed preaching, coupled with a
              great manifestation of the power of God has seen over 1 million
              saved, thousands healed, drastically changed, baptised in the Holy
              Spirit and filled with a fresh touch of revival power.
            </MKTypography>
            <MKTypography variant="body1" color="text1" my={1}>
              This is an opportunity for the church to take action, to come
              together to reach out and invite our local community to hear the
              Gospel.
            </MKTypography>
            <MKTypography variant="body1" color="text1" my={1}>
              At an initial planning meeting we sat around and asked &quot;what
              about time, effort and resource? Is it worth the investment? How
              many souls saved would make it worth it?&quot;
            </MKTypography>
            <MKTypography variant="h5" color="text1" mt={3} mb={6}>
              The answer was ONE!
            </MKTypography>
            <MKButton
              variant="gradient"
              color="dark"
              component={MuiLink}
              href={DownloadLinkPDF}
              target="_blank"
              rel="noreferrer"
              sx={{ ml: 2 }}
            >
              Download PDF
            </MKButton>
          </Grid>
          <Grid
            item
            xs={12}
            position="absolute"
            left="50%"
            mr={-32}
            width="75%"
            sx={{ my: 5 }}
          >
            <MKBox
              component="img"
              src={tentInside1}
              alt="Tent Meeting"
              height="100%"
              // py={10}
              // width="100%"
              // width="75%"
              display={{ xs: "none", md: "block" }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Banner;
