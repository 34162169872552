/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
// import { Link as RouterLink } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "layouts/Navbars/DefaultNavbar";
import DefaultFooter from "layouts/Footers/DefaultFooter";

// Routes
// import { routes, footerRoutes, DefaultNavbar, DefaultFooter } from "internal";
import navbarRoutes from "routes/navbar.routes";
import footerRoutes from "routes/footer.routes";

function SocialMediaTerms() {
  return (
    <>
      <DefaultNavbar
        routes={navbarRoutes}
        /* action={{
          type: "internal",
          route: "/login",
          label: "login",
          color: "dark",
        }} */
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Social Media Terms of Use
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Last Updated: 21 April 2022. The electronic version of this policy is the
                    approved and current version. Any printed version is uncontrolled and may not be
                    current.
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="body2" color="text" mt={6}>
                    We like reading your comments on our social media accounts, but please keep them
                    on-topic and respectful. By using our{" "}
                    <a href="https://www.facebook.com/onechurchpenrith">Facebook</a>, and{" "}
                    {/* <a href="https://www.instagram.com/onechurchpenrith/">Instagram</a>,{" "}
                    <a href="https://linkedin.com/onechurchpenrith">LinkedIn</a>,{" "}
                    <a href="https://youtube.com/onechurchpenrith">YouTube</a>,{" "} */}
                    <a href="https://twitter.com/1churchpenrith">Twitter</a> you agree to the
                    following guidelines:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        We encourage you to share your views and respect the views of others
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        You are responsible for the material and comments you post
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Please keep your language polite and appropriate for everyone to see. That
                        means treating the people on the page with basic manners. Bullying, racism
                        or name-calling will not be tolerated.
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        We have set our <quote>Profanity Blocklist</quote> to strong and therefore
                        Facebook will automatically remove any posts/comments that involve any level
                        of profanity
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        We will remove spam and unauthorised advertisements
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        You participate at your own risk, taking personal responsibility for your
                        comments, your username and any information provided
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Do not impersonate the One Church Penrith account or any employee of the
                        organisation
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        We will reply to your comments at our discretion
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        We will remove any defamatory, misleading, irrelevant or unlawful material
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Do not deliberately provoke others or intentionally disrupt conversation
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        We reserve the right to delete posts at our discretion
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        The appearance of external links or comments on this page does not
                        constitute official endorsement on behalf of One Church Penrith
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  <MKTypography variant="body2" color="text">
                    Thank you for being a part of our online community!
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default SocialMediaTerms;
