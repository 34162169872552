// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "layouts/Navbars/DefaultNavbar";
import DefaultFooter from "layouts/Footers/DefaultFooter";

// Routes
// import { routes, footerRoutes, DefaultNavbar, DefaultFooter } from "internal";
import navbarRoutes from "routes/navbar.routes";
import footerRoutes from "routes/footer.routes";

function Privacy() {
  return (
    <>
      <DefaultNavbar
        routes={navbarRoutes}
        /* action={{
          type: "internal",
          route: "/login",
          label: "login",
          color: "dark",
        }} */
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Privacy Policy
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Last Updated: 21 April 2022. The electronic version of this policy is the
                    approved and current version. Any printed version is uncontrolled and may not be
                    current.
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Personal Information
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    You should read this policy before providing us with any personal (including
                    sensitive) information or before using our website. By providing us with your
                    personal information or by using the website, you are confirming your agreement
                    to the contents of this policy. From time to time it may be necessary for us to
                    review and revise this policy. We reserve the right to change this policy at any
                    time.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    One Church Penrith as an Organisation
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Protecting your privacy is fundamental to the way we care for people.
                    <br />
                    For the purpose of this Privacy Policy, &apos;One Church Penrith&apos; (and
                    &apos;we&apos;, &apos;our&apos;, &apos;us&apos;) means:
                    <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Penrith Christian Ministers Association Inc. (INC3476084, ABN: 57 642 642
                          039)
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Any organisations or bodies corporate owned or operated by any of the
                          bodies above.
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Your privacy is important to us
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    One Church Penrith is committed to upholding the Australian Privacy Principles
                    contained in the Privacy Act 1988. We recognise the importance of privacy and
                    community awareness regarding the collection, use, disclosure and security of
                    personal (including sensitive) information which we may collect during the
                    course of our many functions.
                    <br />
                    <br />
                    Regardless of your relationship with us, we understand that sharing personal
                    information can be confronting, especially for population groups who have
                    experienced discrimination, harassment and bullying based on age, sex, gender
                    identity, sexual orientation, disability, pregnancy, intersex, marital status,
                    or cultural diversity (including Aboriginal or Torres Islanders).
                    <br />
                    <br />
                    This Privacy Policy sets out how we manage your personal information and protect
                    your privacy.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    The personal information we collect and hold
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    One Church Penrith performs many functions and provides a wide range of services
                    and programs across New South Wales, particularly around the Penrith City Local
                    Government Area. We will only collect personal (including sensitive) information
                    that is necessary for us to carry out these functions and provide these services
                    and programs; we will only collect sensitive when we have your consent or when
                    we are required or authorised by law to collect such information.
                    <br />
                    <br />
                    Personal information which fits the definition of sensitive information under
                    the Act generally requires a higher level of privacy protection than other
                    personal information. We do however aim to treat all of your personal
                    information with the utmost care.
                    <br />
                    <br />
                    The type of information we may collect, hold and use, varies depending on the
                    purpose for which it is collected but may include the following (amongst
                    others):
                    <br />
                    <br />
                    <strong>Supporters</strong> - as a supporter of One Church Penrith&apos;s
                    mission and activities, we may need to collect your name and contact details. As
                    a valued financial supporter of our fundraising activities, we may also need to
                    collect your bank account or credit card details.
                    <br />
                    <br />
                    <strong>
                      Service recipients, program participants or clients of our other services
                    </strong>
                    - as a recipient of, or participant in, one of our many services and programs
                    (such as aged care, recovery, welfare, employment assistance, social or other
                    services and programs), we may need to collect your personal information like
                    your name, contact details, income status and emergency contact details, or
                    sensitive information like your health, religious beliefs, sexual orientation,
                    ethnicity and criminal record as well as information about gender identity and
                    intersex status.
                    <br />
                    <br />
                    <strong>Customers</strong> - as a customer at one of our many stores, we may
                    need to collect your name and contact details at the time of purchase or in
                    connection with your donation of goods.
                    <br />
                    <br />
                    <strong>Personnel</strong> - as an officer, employee, volunteer or member of One
                    Church Penrith, we may need to collect personal information like your name,
                    contact details, bank account, taxation details, qualifications, previous
                    experience and emergency contact details or sensitive information like your
                    health, sexual orientation and ethnicity as well as information about gender
                    identity and intersex status.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    How we collect and keep safe your personal information
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    How we collect and store personal (including sensitive) information varies
                    depending on the purpose for which it is collected. We may collect your personal
                    information:
                    <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Directly from you, either in person, over the phone or by email;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          From third parties where you have specifically authorised us to do so; or
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Through our website including through forms on our website.
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                    We will always collect your personal information directly from you unless it is
                    impracticable to do so. This would usually be done in person, over the telephone
                    or by email.
                    <br />
                    <br />
                    Examples may include:
                    <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Copies of your written correspondence with us;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Copies of receipts and/or transaction records in relation to your
                          financial support of our fundraising activities;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Copies of your application form, request for assistance and/or other
                          associated documents (including documents generated during your
                          participation in a program with, or otherwise during your interactions
                          with us) and information that you may provide to us in relation to one of
                          the many services and programs we offer; and
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Copies of your volunteer agreement or employment agreement and any
                          associated documents and information you provide to us in connection with
                          your volunteering for or employment with One Church Penrith.
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                    We may keep copies of the above documents (in physical and/or electronic form,
                    at our election) as is necessary to carry out our functions and provide our
                    services and programs. All personal information is securely stored at all times
                    by us or an authorised external service provider and only authorised people will
                    have access to the above documents and information.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Why we collect, hold and use personal information
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    We may collect, hold, use and disclose personal (including sensitive)
                    information for purposes necessary to carry out our functions and provide our
                    services and programs. Generally, these purposes include:
                    <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          To keep in contact with you as a supporter of One Church Penrith and to
                          inform you about the role and extent of our work and mission;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          To manage our fundraising activities and for taxation record-keeping
                          purposes associated with your donation to One Church Penrith;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          To assess your welfare needs and to provide you with the welfare and
                          community services and assistance we offer;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          To inform and improve our service design, delivery and resourcing;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          To provide you with the necessary care and assistance during your time as
                          a resident of, or recipient of services from, one of our services, centres
                          or facilities (such as aged care centres, recovery services, crisis
                          accommodation services, etc.);
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          To provide you with the services requested by you from us;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          To facilitate and manage your purchase from us, or donation to us;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          To comply with necessary business and accounting standards;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          To comply with our reporting obligations to the Australian Taxation Office
                          and other government agencies and public sector bodies; or
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          To facilitate and manage your employment relationship or volunteer
                          arrangement with One Church Penrith.
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                    We are committed to maintaining your privacy and we will only use your personal
                    information for a permitted purpose for which we have collected the information.
                    There is no obligation for you to provide us with any of your personal
                    information however if you choose not to provide us with your personal
                    information, we may not be able to provide you with the services that you
                    require.
                    <br />
                    <br />
                    You have the option of not identifying yourself or using a pseudonym when
                    dealing with us in relation to a particular matter, unless we believe it is
                    impracticable to do so in the circumstances. If you wish to deal with us in this
                    manner, you must tell us in writing so that we can consider if your request is
                    practicable.
                    <br />
                    <br />
                    We may use personal information (including some sensitive) information to
                    generate aggregated statistical data for the purpose of reporting to Government
                    agencies and to plan for improvements to our services. We take reasonable steps
                    to ensure that the information we report to Government agencies is de-identified
                    and aggregated, so that the statistical data and reports cannot be used to
                    identify you.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    How we may share your personal information
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    If necessary to carry out our functions and provide our services and programs,
                    we may need to disclose your personal (including sensitive) information to
                    external service providers (such as information technology service providers,
                    legal service providers, mailing houses, call centres, other community service
                    providers, etc.) and other organisations.
                    <br />
                    <br />
                    We will only share your personal information in accordance with your express
                    consent and instructions, subject to the exclusions set out in the Australian
                    Privacy Principles, or in accordance with the specific Privacy Notice provided
                    to you by us at or near the time of collection of your personal information.
                    <br />
                    <br />
                    We do not supply our database information to other marketing organisations not
                    acting on our behalf.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    How we use Google Remarketing
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    We do not collect any individual personal information on visitors to our website
                    without your knowledge. We do collect non-personal information that allows us to
                    log information about visitors to our website for statistical purposes, such as
                    the:
                    <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Number of unique sessions as identified by server address and top-level
                          domain name (e.g. .com, .gov, .org, etc.);
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Number of page hits;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Pages accessed or downloaded by session;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Type of browser being used;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Length of time spent on the site and on particular pages;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Device being used (e.g. desktop or mobile); and
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Type of mobile device being used (e.g. iPhone 4).
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                    In addition:
                    <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          We have implemented and use Display Advertising;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          We use Remarketing with Google to advertise online;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Third-party vendors, including Google, show our ads on sites across the
                          Internet;
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          We and third-party vendors, including Google, use first-party cookies
                          (such as the Google Analytics cookie) and third-party cookies (such as the
                          DoubleClick cookie) together to inform, optimise, and serve ads based on
                          someone&apos;s past behaviour to our website; and
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Users can opt out by utilising Google&apos;s Opt-out Browser Add-on
                          (https://tools.google.com/dlpage/gaoptout/) and/or Ads Preferences Manager
                          (https://www.google.com/settings/ads/).
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    What international disclosure do we make
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    One Church Penrith is based in New South Wales. While every effort is made to
                    keep personal (including sensitive) information we hold in Australia only, in
                    order to carry out our functions and provide our programs and services we may
                    need to disclose personal information to overseas recipients, unless expressly
                    prohibited by law.
                    <br />
                    <br />
                    In the unlikely event such disclosure is required, it will generally involve
                    disclosure to international members of One Church Penrith and only to the extent
                    necessary to carry out our functions and provide our programs and services.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    How you can access and seek correction of your personal information
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    You have the right to access your personal (including sensitive) information,
                    subject to some exceptions allowed by law. If you would like to access or seek
                    correction of your personal information, please contact our Privacy Office at
                    the contact details below. For security reasons you will be asked to put your
                    request in writing.
                    <br />
                    <br />
                    To enable us to verify your request we require you to advise the following:
                    <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Your full name
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Address
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          Contact phone number
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography variant="body2" color="text" fontWeight="regular">
                          The relevant department or service (if known) that your request relates to
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                    There is no application fee for making a request to access your personal
                    information. While we do not normally charge for providing individuals with
                    access to their personal information, we reserve the right to charge you for our
                    reasonable costs in complying with a request for access. These costs will not be
                    excessive.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    How you can complain about a breach of the Australian Privacy Principles
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    We are committed to protecting your privacy and upholding the Australian Privacy
                    Principles. If you believe we have breached the Australian Privacy Principles
                    please contact our Privacy Office with your concerns at the contact details
                    below.
                    <br />
                    <br />
                    We take all complaints very seriously and we will endeavour to respond to your
                    complaint and address your concerns as soon as reasonably practicable.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    How we comply with the Notifiable Data Breaches Scheme
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    We will notify you in the event your personal (including sensitive) information
                    is involved in a data breach that is likely to result in serious harm.
                    <br />
                    <br />
                    This notification will include recommendations about the steps you should take
                    in response to the breach. We will also notify The Australian Information
                    Commissioner of eligible data breaches.
                    <br />
                    <br />
                    Each suspected data breach reported to us will be assessed to determine whether
                    it is likely to result in serious harm, and as a result require notification.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    How you can contact our Privacy Office
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    We welcome and appreciate your feedback or comments in relation to our Privacy
                    Policy and the collection of personal (including sensitive) information. If you
                    have any questions in relation to privacy please contact our Privacy Office at
                    the contact details below.
                    <br />
                    {/* <br />
                    Privacy Office
                    <br />
                    One Church Penrith
                    <br />
                    PO Box 123456 Penrith NSW 2750
                          <br /> */}
                    Email:{" "}
                    {/* <a href="mailto:privacy@onechurchpenrith.org.au">
                      privacy@onechurchpenrith.org.au
                    </a> */}
                    <a href="mailto:contact@onechurchpenrith.org.au">
                      contact@onechurchpenrith.org.au
                    </a>
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Privacy;
