import logoBaptistCareHopeStreet from "assets/api/organisations/baptist-care-hope-street-logo.png";
import logoBridgingTheGap from "assets/api/organisations/bridging-the-gap.png";
import logoC3Penrith from "assets/api/organisations/c3-penrith.png";
import logoElevationChurch from "assets/api/organisations/elevation-church.jpg";
import logoGWFC from "assets/api/organisations/greater-west-for-christ.png";
import logoHillsong from "assets/api/organisations/hillsong-church.png";
import logoINChurch from "assets/api/organisations/imagine-nations-church.png";
import logoKingswoodAnglican from "assets/api/organisations/kingswood-anglican-church.png";
import logoNewHorizons from "assets/api/organisations/new-horizons-church.png";
import logoPenrithAnglican from "assets/api/organisations/penrith-anglican-church.png";
import logoPenrithBaptist from "assets/api/organisations/penrith-baptist-church.png";
import logoPenrithCityChapel from "assets/api/organisations/penrith-city-chapel.png";
import logoPenrithPresbyterian from "assets/api/organisations/penrith-presbyterian-church.png";
import logoSalvos from "assets/api/organisations/salvos.png";
import logoStMarysBaptist from "assets/api/organisations/st-marys-baptist-logo.png";
import logoStrongNation from "assets/api/organisations/strong-nation-church.png";
import logoRockCommunityCentre from "assets/api/organisations/the-rock-community-centre.png";
import logoUniting from "assets/api/organisations/uniting-church-synod-nsw-act.png";

const organisations = [
  {
    name: "Baptist Care - Hope Street Women's Services, St Marys",
    logoImage: logoBaptistCareHopeStreet,
    logoPath: "assets/api/organisations/baptist-care-hope-street-logo.png",
    url: "https://baptistcare.org.au/centre/st-marys",
    categories: ["fbo"],
  },
  {
    name: "Bridging The Gap",
    logoImage: logoBridgingTheGap,
    logoPath: "assets/api/organisations/briding-the-gap.png",
    url: "https://www.btg.org.au/",
    categories: ["fbo"],
  },
  {
    name: "C3 Church Penrith",
    logoImage: logoC3Penrith,
    logoPath:
      "assets/api/organisations/christian-community-churches-of-australia.png",
    url: "https://c3churchpenrith.com.au/",
    categories: ["church"],
  },
  {
    name: "Elevation Church",
    logoImage: logoElevationChurch,
    logoPath: "assets/api/organisations/elevation-church.jpg",
    url: "https://penrith.elevationchurch.com.au/",
    categories: ["church"],
  },
  {
    name: "Greater West for Christ",
    logoImage: logoGWFC,
    logoPath: "assets/api/organisations/greater-west-for-christ.png",
    url: "https://www.gwfc.com.au/",
    categories: ["fbo"],
  },
  {
    name: "Greater West Salvos - Glenmore Park",
    logoImage: logoSalvos,
    logoPath: "assets/api/organisations/salvos.png",
    url: "https://www.salvationarmy.org.au/penrith/",
    categories: ["church"],
  },
  {
    name: "Hillsong",
    logoImage: logoHillsong,
    logoPath: "assets/api/organisations/hillsong-church.png",
    url: "https://hillsong.com/australia/greaterwest/",
    categories: ["church"],
  },
  {
    name: "Imagine Nations Church",
    logoImage: logoINChurch,
    logoPath: "assets/api/organisations/imagine-nations-church.png",
    url: "https://inchurch.com.au/",
    categories: ["church"],
  },
  {
    name: "Kingswood Anglican Church",
    logoImage: logoKingswoodAnglican,
    logoPath: "assets/api/organisations/kingswood-anglican-church.png",
    url: "https://www.kingswoodanglican.org/",
    categories: ["church"],
  },
  {
    name: "New Horizons - St Marys",
    logoImage: logoNewHorizons,
    logoPath: "assets/api/organisations/new-horizons-church.png",
    url: "https://newhorizonschurch.org.au/",
    categories: ["church"],
  },
  {
    name: "Penrith Anglican Church",
    logoImage: logoPenrithAnglican,
    logoPath: "assets/api/organisations/penrith-anglican-church.png",
    url: "https://www.penrithanglican.org/",
    categories: ["church"],
  },
  {
    name: "Penrith Baptist Church",
    logoImage: logoPenrithBaptist,
    logoPath: "assets/api/organisations/penrith-baptist-church.png",
    url: "https://penrithbaptist.com.au/",
    categories: ["church"],
  },
  {
    name: "Penrith City Chapel",
    logoImage: logoPenrithCityChapel,
    logoPath: "assets/api/organisations/penrith-city-chapel.png",
    url: "https://www.penrithcitychapel.org.au/",
    categories: ["church"],
  },
  {
    name: "Penrith Presbyterian Church",
    logoImage: logoPenrithPresbyterian,
    logoPath: "assets/api/organisations/penrith-presbyterian-church.png",
    url: "http://www.penrithpresbyterian.com/",
    categories: ["church"],
  },
  {
    name: "Penrith Uniting Church",
    logoImage: logoUniting,
    logoPath: "assets/api/organisations/uniting-church-synod-nsw-act.png",
    url: "https://penrith.uca.org.au/",
    categories: ["church"],
  },
  {
    name: "St Marys Baptist Church",
    logoImage: logoStMarysBaptist,
    logoPath: "assets/api/organisations/st-marys-baptist-logo.png",
    url: "https://www.stmarysbaptist.org.au/",
    categories: ["church"],
  },
  {
    name: "Strong Nation Church",
    logoImage: logoStrongNation,
    logoPath: "assets/api/organisations/strong-nation-church.png",
    url: "https://www.strongnation.church/",
    categories: ["church"],
  },
  {
    name: "The Rock Community Centre",
    logoImage: logoRockCommunityCentre,
    logoPath: "assets/api/organisations/the-rock-community-centre.png",
    url: "https://www.trcc.com.au/",
    categories: ["church"],
  },
];

export default organisations;
