// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function RotatingDownloadCardFront({
  bgColor,
  image,
  icon,
  iconSize,
  textColor,
  fontWeight,
  title,
  description,
  opacity,
}) {
  return (
    <MKBox
      display="flex"
      justifyContent="center"
      alignContent="center"
      borderRadius="lg"
      coloredShadow={bgColor}
      width="100%"
      height="100%"
      position="relative"
      zIndex={2}
      sx={{
        backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
          `${linearGradient(
            rgba(gradients[bgColor] ? gradients[bgColor].main : gradients.info.main, opacity),
            rgba(gradients[bgColor] ? gradients[bgColor].main : gradients.info.main, opacity)
          )}, url(${image})`,
        backgroundSize: "cover",
        backfaceVisibility: "hidden",
      }}
    >
      <MKBox py={12} px={3} textAlign="center" lineHeight={1}>
        {icon && (
          <MKTypography variant="h1" fontSize={iconSize} color={textColor} my={2}>
            {typeof icon === "string" ? <Icon>{icon}</Icon> : icon}
          </MKTypography>
        )}
        <MKTypography variant="h3" color={textColor} gutterBottom>
          <span>{title}</span>
        </MKTypography>
        <MKTypography variant="body2" color={textColor} fontWeight={fontWeight} opacity={0.8}>
          <span>{description}</span>
        </MKTypography>
      </MKBox>
    </MKBox>
  );
}

// Setting default props for the RotatingDownloadCardFront
RotatingDownloadCardFront.defaultProps = {
  bgColor: "info",
  opacity: 0.85,
  title: "",
  icon: "",
  iconSize: "h1.fontSize",
  textColor: "white",
  fontWeight: "regular",
};

// Typechecking props for the RotatingDownloadCardFront
RotatingDownloadCardFront.propTypes = {
  bgColor: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string.isRequired,
  icon: PropTypes.node,
  iconSize: PropTypes.string,
  textColor: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  fontWeight: PropTypes.oneOf([false, "light", "regular", "medium", "bold"]),
  title: PropTypes.node,
  description: PropTypes.node.isRequired,
  opacity: PropTypes.number,
};

export default RotatingDownloadCardFront;
