// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function SocialMedia({ content, textColor }) {
  const { socials } = content;

  return (
    <Container>
      <MKTypography variant="h6" color={textColor} mt={8} mb={1}>
        Find us on
      </MKTypography>
      <MKBox display="flex" justifyContent="center" alignItems="center">
        {socials.map(({ icon, link }, key) => (
          <MKTypography
            key={link}
            component="a"
            href={link}
            target="_blank"
            rel="noreferrer"
            variant="body1"
            color={textColor}
            opacity={0.8}
            mr={key === socials.length - 1 ? 0 : 2.5}
            // mr={3}
          >
            {icon}
          </MKTypography>
        ))}
      </MKBox>
    </Container>
  );
}

// Typechecking props for SocialMedia
SocialMedia.propTypes = {
  content: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.bool])
  ).isRequired,
  textColor: PropTypes.oneOf([
    "inherit",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "text",
    "white",
  ]).isRequired,
};

export default SocialMedia;
