/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Switch from "@mui/material/Switch";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKInput from "components/MKInput";
// import MKButton from "components/MKButton";
import ContactUsImage from "assets/img/pages/public-site/home-contact-us.jpg";
// import AboutUsOption from "../components/AboutUsOption";

import NaybaIcon from "assets/img/logos/NAYBA-logo-white.svg";
import PenrithIcon from "assets/img/logos/Penrith Plain.svg";

function ContactList() {
  return (
    <MKBox
      component="section"
      display="grid"
      position="relative"
      minHeight="90vh"
      borderRadius="xl"
      mr={{ xs: 0, lg: -2 }}
      mb={{ xs: 0, lg: -2 }}
      sx={{ overflow: { xs: "hidden", lg: "visible" }, placeItems: "center" }}
    >
      <MKBox
        component="img"
        alt="lake house"
        // src="assets/img/pages/public-site/home-contact-us.jpg"
        src={ContactUsImage}
        width={{ xs: "100%", lg: "50%" }}
        height={{ xs: "100%", lg: "100%" }}
        position="absolute"
        right={0}
        bottom={{ xs: "-25%", lg: "unset" }}
        top={{ xs: 0, lg: "unset" }}
        sx={{
          objectFit: "cover",
          borderTopLeftRadius: ({ borders: { borderRadius } }) => ({ xs: 0, lg: borderRadius.lg }),
        }}
      />
      <Container>
        <Grid container spacing={{ xs: 0, lg: 3 }} sx={{ mt: { xs: 0, lg: 12 } }}>
          <Grid item xs={12} lg={7} justifyContent="center" flexDirection="column">
            <MKBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              shadow="lg"
              borderRadius="xl"
              sx={{
                backgroundColor: ({ functions: { rgba }, palette: { white } }) =>
                  rgba(white.main, 0.8),
                backdropFilter: "saturate(200%) blur(30px)",
                px: { xs: 3, sm: 6 },
                py: { xs: 3, sm: 8 },
                mb: { xs: 0, lg: 8 },
                mt: { xs: 0, lg: -6 },
              }}
            >
              <MKTypography variant="h3" textAlign="center" mb={2}>
                Contact us
              </MKTypography>

              <MKBox py={3} px={{ xs: 3, md: 12, lg: 6, xl: 12 }}>
                <Grid container spacing={3} sx={{ mb: 3 }}>
                  <Grid item xs={12} sx={{ my: 1 }}>
                    <Stack>
                      <MKBox display="flex" alignItems="center" p={2}>
                        <MKBox
                          width="3rem"
                          height="3rem"
                          variant="gradient"
                          bgColor="info"
                          color="white"
                          coloredShadow="info"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius="xl"
                        >
                          <Icon fontSize="large">email</Icon>
                        </MKBox>
                        <MKTypography variant="body2" color="text" pl={2}>
                          <b>General Enquiries</b>
                          <br />
                          <a href="mailto:contact@onechurchpenrith.org.au">
                            contact@onechurchpenrith.org.au
                          </a>
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" alignItems="center" p={2}>
                        <MKBox
                          width="3rem"
                          height="3rem"
                          variant="gradient"
                          bgColor="info"
                          color="white"
                          coloredShadow="info"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius="xl"
                        >
                          <Icon fontSize="large">work</Icon>
                        </MKBox>
                        <MKTypography variant="body2" color="text" pl={2}>
                          <b>Business</b>
                          <br />
                          <a href="mailto:business@onechurchpenrith.org.au">
                            business@onechurchpenrith.org.au
                          </a>
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" alignItems="center" p={2}>
                        <MKBox
                          width="3rem"
                          height="3rem"
                          variant="gradient"
                          bgColor="info"
                          color="white"
                          coloredShadow="info"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius="xl"
                          sx={{ p: 1 }}
                        >
                          {/* <Icon fontSize="large">email</Icon> */}
                          <img src={PenrithIcon} alt="Penrith icon" />
                        </MKBox>
                        <MKTypography variant="body2" color="text" pl={2}>
                          <b>Local Government</b>
                          <br />
                          <a href="mailto:city@onechurchpenrith.org.au">
                            city@onechurchpenrith.org.au
                          </a>
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" alignItems="center" p={2}>
                        <MKBox
                          width="3rem"
                          height="3rem"
                          variant="gradient"
                          bgColor="info"
                          color="white"
                          coloredShadow="info"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius="xl"
                          sx={{ p: 1 }}
                        >
                          {/* <Icon fontSize="medium">email</Icon> */}
                          <img src={NaybaIcon} alt="NAYBA icon" />
                        </MKBox>
                        <MKTypography variant="body2" color="text" pl={2}>
                          <b>Social Impact</b>
                          <br />
                          <a href="mailto:nayba@onechurchpenrith.org.au">
                            nayba@onechurchpenrith.org.au
                          </a>
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" alignItems="center" p={2}>
                        <MKBox
                          width="3rem"
                          height="3rem"
                          variant="gradient"
                          bgColor="info"
                          color="white"
                          coloredShadow="info"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius="xl"
                        >
                          <Icon fontSize="large">paid</Icon>
                        </MKBox>
                        <MKTypography variant="body2" color="text" pl={2}>
                          <b>Accounts</b>
                          <br />
                          <a href="mailto:accounts@onechurchpenrith.org.au">
                            accounts@onechurchpenrith.org.au
                          </a>
                        </MKTypography>
                      </MKBox>
                    </Stack>
                  </Grid>
                </Grid>
              </MKBox>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ContactList;
/*  const contactMap = [

      label: "Local Government",
      email: "city@onechurchpenrith.org.au",
      emailLink: "mailto:city@onechurchpenrith.org.au",
    },
    {
      id: "4",
      label: "Social Impact",
      email: "nayba@onechurchpenrith.org.au",
      emailLink: "mailto:nayba@onechurchpenrith.org.au",
    },
    {
      id: "5",
      label: "Accounts",
      email: "accounts@onechurchpenrith.org.au",
      emailLink: "mailto:accounts@onechurchpenrith.org.au",
    },
  ]; */
