/** 
  All of the routes for the One Church Penrith website are added here.
  To avoid circular references, this array only declares the route paths and elements.
  It is not used to render the routes in any links (e.g. in the navbar). A separate
  array is maintained in `navbar.routes.js` which contains the paths but not the 
  elements - avoiding circular references and allowing richer formatting details to be
  included in a centralised location.
*/

// Pages
import Home from "pages/public-site/home";
// import { TheGreatWait, Privacy, Terms, SocialMediaTerms } from "internal";
/* import Services from "pages/public-site/services";
import Churches from "pages/public-site/churches";
import ServiceMap from "pages/public-site/map";
import Events from "pages/public-site/events";
import TheGreatWait from "pages/public-site/events/the-great-wait"; */
import TentRevival from "pages/public-site/events/tim-hall-tent";
import KingdomBusinessNetwork from "pages/public-site/kbn";

/* import Team from "pages/public-site/about/team";
import Blog from "pages/public-site/about/blog";
import ContactUs from "pages/public-site/about/contact";
import History from "pages/public-site/about/history";

import Faq from "pages/public-site/support/faq"; */
import Privacy from "pages/public-site/support/privacy";
import SocialMediaTerms from "pages/public-site/support/social-media-terms";
import Terms from "pages/public-site/support/terms";

const routes = [
  {
    key: 0,
    path: "/",
    element: <Home />,
  },
  {
    key: 0.1,
    path: "/home",
    element: <Home />,
  },
  /*
  {
    key: 2,
    path: "/services",
    element: <Services />,
  },
  {
    key: 3,
    path: "/churches",
    element: <Churches />,
  },
  {
    key: 4,
    path: "/map",
    element: <ServiceMap />,
  },
  {
    key: 1,
    path: "/events",
    element: <Events />,
  },
  {
    key: 1.1,
    path: "/events/the-great-wait",
    element: <TheGreatWait />,
  },
  */
  {
    key: 1.1,
    path: "/events/tent",
    element: <TentRevival />,
  },
  {
    key: 2.1,
    path: "/kbn",
    element: <KingdomBusinessNetwork />,
  },
  /*
  {
    key: 5.1,
    path: "/about-us/contact",
    element: <ContactUs />,
  },
  {
    key: 5.2,
    path: "/about-us/team",
    element: <Team />,
  },
  {
    key: 5.3,
    path: "/about-us/blog",
    element: <Blog />,
  },
  {
    key: 5.4,
    path: "/about-us/history",
    element: <History />,
  },
  */
  {
    key: 2.1,
    path: "/privacy",
    element: <Privacy />,
  },
  {
    key: 2.2,
    path: "/terms",
    element: <Terms />,
  },
  {
    key: 2.3,
    path: "/social-media-terms",
    element: <SocialMediaTerms />,
  },
];

export default routes;
