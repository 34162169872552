// create-react-app template components
// import logo from './assets/img/logos/OCP-logo-O.svg';
import "assets/css/App.css";
// import "@fortawesome/fontawesome-svg-core";
// import "@fortawesome/free-solid-svg-icons";
// import "@fortawesome/react-fontawesome";

import { useEffect } from "react";

// react-router components
// import { Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";
// import { Routes, Route, Outlet, useLocation } from "react-router-dom";
import { Routes, Route, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";
// import Home from "layouts/pages/home";
// import Home from "pages/public-site/home";
// import TheGreatWait from "pages/public-site/events/the-great-wait";
// import Privacy from "pages/public-site/support/privacy";
// import Terms from "pages/public-site/support/terms";
// import SocialMediaTerms from "pages/public-site/support/social-media-terms";

// Material Kit 2 PRO React routes
import routes from "routes/routes";

export default function App() {
  // const { pathname } = useLocation();
  const pathname = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => <Route path={route.path} element={route.element} key={route.key} />);

  /*
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });
  */

  // eslint-disable-next-line
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* eslint-disable-line */}
      <Routes>{getRoutes(routes)}</Routes>
    </ThemeProvider>
  );
  // <Route path="/*" element={<Layout />}>
  //   {getRoutes(routes)}
  //   <Route path="/" element={<Home />} />
  //   <Route path="/*" element={<Home />} />;
  // </Route>

  // <Route path="/" element={<Home />} />
  // <Route path="/*" component="index.html" /> ????????????????????????????
  /*
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/ * eslint-disable-line * /}
      <Routes>
        {getRoutes(routes)}
        <Route path="/*" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="events/the-great-wait" element={<TheGreatWait />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="social-media-terms" element={<SocialMediaTerms />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
  */
}
// <Route path="/" element={<Home />} />
// <Route path="*" element={<Navigate to="/" />} />

// An <Outlet> renders whatever child route is currently active, so you can think about
// this <Outlet> as a placeholder for the child routes we defined above.
// function Layout() {
//   return <Outlet />;
// }
