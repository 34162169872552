import { useEffect, useRef } from "react";

// rellax
import Rellax from "rellax";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React layouts
import DefaultNavbar from "layouts/Navbars/DefaultNavbar";
import DefaultFooter from "layouts/Footers/DefaultFooter";

// Routes
// import { routes, footerRoutes, DefaultNavbar, DefaultFooter } from "internal.js";
import navbarRoutes from "routes/navbar.routes";
import footerRoutes from "routes/footer.routes";

// Images
import bgImage from "assets/img/pages/public-site/tent/tent-outside-1.jpg";
// import tentOutside2 from "assets/img/pages/public-site/tent/tent-outside-2.jpg";
// import tentInside1 from "assets/img/pages/public-site/tent/tent-inside-1.jpg";
// import tentInside2 from "assets/img/pages/public-site/tent/tent-inside-2.jpg";
// import tentPerson from "assets/img/pages/public-site/tent/tent-person.jpg";

// DesktopApp page sections
import Banner from "./components/Banner";
import Information from "./components/Information";

function TentRevival() {
  const headerRef = useRef(null);

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);

  return (
    <>
      <DefaultNavbar
        routes={navbarRoutes}
        /* action={{
          type: "internal",
          route: "/login",
          label: "login",
          color: "info",
        }} */
        sticky
      />
      <MKBox
        ref={headerRef}
        minHeight="85vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" flexDirection="column">
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Penrith &amp; the Greater West Tent Revival
            </MKTypography>
            <MKTypography
              variant="h3"
              color="white"
              opacity={0.8}
              mb={2}
              mr={{ xs: 0, sm: 6 }}
              pr={{ xs: 0, sm: 6 }}
            >
              Tim Hall Ministries is COMING TO PENRITH with the MIGHTY GOSPEL TENT
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKTypography
          variant="h3"
          color="white"
          opacity={0.8}
          mb={2}
          mr={{ xs: 0, sm: 6 }}
          pr={{ xs: 0, sm: 6 }}
        >
          Tim Hall Ministries is COMING TO PENRITH with the MIGHTY GOSPEL TENT
        </MKTypography>
        <Banner />
        <Information />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default TentRevival;
